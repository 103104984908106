export const environment = {
  production: true,
  CONFIG_FILE: "assets/config/env.json",
  date_time_format: 'dd/MM/yyyy HH:mm:ss',
  date_format: 'dd/MM/yyyy',
  time_format: 'HH:mm:ss',
  date_time_format_moment: 'DD/MM/YYYY HH:mm:ss',
  date_format_server: 'YYYY-MM-DD',
  date_time_format_server: 'YYYY-MM-DD HH:mm:ss',
  time_format_moment: 'HH:mm:ss',
  date_format_moment: 'DD/MM/YYYY',
  step_finished_exam: 5,
  mix_pusher_app_key:'3B94A1DF-EEB4-4FEF-B34A-E07F02619597',
  mix_pusher_app_cluster: 'mt1',
  study_viewer_url_radiant: 'radiant://?n=pstv&v=0020000D&v=',
  study_viewer_url_oviyam: 'http://udilaudo.com.br:8181/viewer.html?studyUID=',
  apis: {
    udi_laudos_api: `https://api.udilaudo.com.br/public/api`,
    udi_laudos_server: `https://api.udilaudo.com.br/public`,
    url_upload: `https://api.udilaudo.com.br/public/api/v1/users/imageProfile`,
    url_upload_file_profile: `https://api.udilaudo.com.br/public/api/v1/users/imageProfile`
  }
};
