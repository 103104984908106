import { ThemeService } from './core/services/theme.service';
import { Component, OnInit } from '@angular/core';
import { UserService } from './core/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'udilaudo';

  constructor(){

  }

  ngOnInit(){
    var body = document.body;
    body.classList.add("hold-transition");
    body.classList.add("skin-blue");
    body.classList.add("sidebar-mini");

    window.localStorage.setItem('not_automatic_open', 'false')

    if(window.location.hostname != "localhost" && window.location.protocol.indexOf('http:') >= 0 && environment.production){
      window.location.href = `https://${window.location.host}/${window.location.pathname}`
    }


    // if(this.authSvc.getUser()){
    //   this.socket.emit('set_room', this.authSvc.getUser());
    // }
  }


}
